@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #20242d;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

span, .primary-color{
  background-image: linear-gradient(to right, #32f342, #a9f58b);
  -webkit-background-clip: text;
  color: transparent;
}

.bg-primary-color{
  background-image: linear-gradient(to right, #5eb60d, #a5d380);
}